import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RangeBar from '../RangeBar/RangeBar';
import style from './CreateProduct.module.scss'
import axios from 'axios';
import '../../globalData';
import {storage} from "../../firebase"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

//Component pour créer un product
const CreateProduct =(props) => {
    const [state, setState] = useState({
            title: '',
            description: '',
            rating: '5',
            imageUrl:'',
            prix: '',
            prix_offre: '',
            region: '',
            AOC: ''
    });
    const [imageUpload, setImageUpload] = useState(null);
    const fromRef = useRef();
    const navigate = useNavigate();

    //Gérer le formulaire
    const handleChange = (dataType) => {
        return (e) => setState({...state,[dataType]: e.target.value});
    }

    //Gérer l'image
    const onChangeImage = (e) => {
        setImageUpload(e.target.files[0]);
        let fileSize = parseInt(e.target.files[0].size/1000);
        fromRef.current.innerHTML = `文件大小${fileSize}k -(不能大于1000k)`;
    }

    //Gérer submit du formulaire
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = sessionStorage.getItem("token"); 
            if (imageUpload.name === undefined){
                alert('请选择上传酒类图片')
                return;
            }
            if (imageUpload != null) {
                const imageRef = ref(storage,`images/${imageUpload.name+uuidv4()}`);
                await uploadBytes(imageRef, imageUpload).then(async (snapshot) => {
                    await getDownloadURL(snapshot.ref).then(url => {
                        state.imageUrl = url
                    })
                });              
            }
            const product = state;
            const res = await axios.post(`${props.url}/api/products/create`, product, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token
              }
            });
            if (res.status === 201) {
                if (window.confirm('Nouveau vins enregistré! 红酒创建成功!')){
                    navigate('/');
                };
            }
          } catch (err) {
            alert(err.message);
          }
        }
            
    return (
        <>
        <form className={style.form} onSubmit={handleSubmit}>
            <div className={style.container}>
                <label htmlFor="title">酒名</label>
                <input type="text" name="title" id="title" onChange={handleChange("title")} required/>
            </div>
            <div className={style.container}>
                <label htmlFor="region">大区</label>
                <input type="text" name="region" id="region" required onChange={handleChange("region")}/>
            </div>
            <div className={style.container}>
                <label htmlFor="AOC">产区</label>
                <input type="text" name="AOC" id="AOC" required onChange={handleChange("AOC")} />
            </div>
            <div className={style.container}>
                <label htmlFor="description">口感</label>
                <textarea name="description" id="description" className={style.description} rows="5" onChange={handleChange("description")} ></textarea>
            </div>
            <div className={style.containerImg}>
                <input name="file" ref={fromRef} type="file" accept="image/gif,image/jpeg,image/jpg,image/png,image/webp" onChange={onChangeImage}/>
                <p ref={fromRef}></p>
            </div>
            <div className={style.container}>
                <label htmlFor="prix">价格</label>
                <input name="prix" type="text" id="prix" required onChange={handleChange("prix")}/>
            </div>
            <div className={style.container}>
                <label htmlFor="prix_offre">促销价</label>
                <input type="text" id="prix_offre" name="prix_offre" onChange={handleChange("prix_offre")} />
            </div>
            <RangeBar result={handleChange} currentRating={state.rating?state.rating:"5"}/>
            <button type='submit' className={style.submit}>提交</button>
        </form>
        </>
    )
}


export default CreateProduct