import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Login from '../../components/Login/Login.jsx'

//La page SignIn
const SignIn = (props) => {
    const navigate = useNavigate();
    //Envoyer les données de l'utilisateur au backend
    const handleSubmit = (userInfo) => {
        axios.post(`${props.url}/api/users`,userInfo)
        .then((res) => {
          if (res.status === 200) {
            sessionStorage.setItem("token", res.data.token);
            navigate('/Admin');
          }else { // fail to login
            window.alert('登录失败、');
            return;
        }})
        .catch((error) => {
          window.alert(error.response.data.error);
        })
      };
    return (
        <>
        <Login userSubmit={handleSubmit}/>
        </>
    )
}

export default SignIn