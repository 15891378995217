import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBm9mbSmPC2WBqN40aRANNRklqdL0k8qbs",
  authDomain: "cave88-3713b.firebaseapp.com",
  projectId: "cave88-3713b",
  storageBucket: "cave88-3713b.firebasestorage.app",
  messagingSenderId: "719709220479",
  appId: "1:719709220479:web:34a0ff11013ed4daf2194a",
  measurementId: "G-F5L2PFL57P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);